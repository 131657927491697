import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthGuard } from './guard/auth.guard';
import { NoAuthGuard } from './guard/no-auth.guard';

@NgModule({
  imports: [HttpClientModule],
  providers: [AuthGuard, NoAuthGuard],
})
export class CoreModule {}
