import { Component, OnInit, Inject } from '@angular/core';
import { AppConstant } from 'src/app/core/constants/app.constant';
import { OKTA_AUTH as Okta_Auth } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthService } from 'src/app/core/service/auth.service';

interface Item {
  routerLink: string;
  image: string;
  alt: string;
  title: string;
  dataCy: string;
  otherName?: string;
  hasTrademark?: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  items: Item[] = AppConstant.appList;
  appName: string = AppConstant.appName;
  appDescription: string = AppConstant.appDescription;
  fileName: any;
  fileUrl: any;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(
    @Inject(Okta_Auth) public oktaAuth: OktaAuth,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.storeAccessToken();
  }

  storeAccessToken(): void {
    if (this.authService.hasAccessToken()) {
      const accessToken = this.parseAccessToken(
        this.authService.getAccessToken(),
      );
      this.authService.setCustomValue(JSON.stringify(accessToken));
    }
  }

  private parseAccessToken(token: string | null): string | null {
    try {
      return token ? JSON.parse(token).accessToken : null;
    } catch (error) {
      return null;
    }
  }

  login() {
    this.oktaAuth.signInWithRedirect({ originalUri: '/' });
  }

  logout() {
    this.oktaAuth.signOut();
  }

  routerLink(path: string) {
    window.open(path, '_blank');
  }

  showSmdcReleaseNotes() {
    this.fileName = "SMDC - Launch Page Release Notes.pdf";
    this.fileUrl = "./assets/ReleaseNotesFiles/SMDC - Launch Page Release Notes.pdf";
    window.open(this.fileUrl, "_blank");
  }
}
