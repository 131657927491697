import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private accessTokenKey = 'okta-token-storage';
  private customKey = 'smdc-share-token-storage';

  getAccessToken(): string | null {
    return localStorage.getItem(this.accessTokenKey);
  }

  setAccessToken(token: string): void {
    localStorage.setItem(this.accessTokenKey, token);
  }

  // Check if access token exists
  hasAccessToken(): boolean {
    return !!this.getAccessToken();
  }

  // Store another key/value pair in local storage
  setCustomValue(value: string): void {
    localStorage.setItem(this.customKey, value);
  }

  // Get the stored value
  getCustomValue(): string | null {
    return localStorage.getItem(this.customKey);
  }
}
