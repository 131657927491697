// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AppConstant {
  export const appName = 'SMDC';
  export const appDescription =
    'Sperry Mechanical Datastore and Configurators';

    export const appList = [
      {
        routerLink: '/imotors/',
        image: '/assets/images/icons/svgs/imotors_icon.svg',
        alt: 'iMotors icon',
        title: 'iMotors',
        dataCy: 'iMotors-Icon',
      },
      {
        routerLink: '/icruise/',
        image: '/assets/images/icons/svgs/icruise_icon.svg',
        alt: 'iCruise',
        title: 'iCruise',
        dataCy: 'Icruise-Icon',
        hasTrademark: true,
      },
      {
        routerLink: '/geometrydb/',
        image: '/assets/images/icons/svgs/geometry_db_icon.svg',
        alt: 'Geometry db icon',
        title: 'Geometry db',
        dataCy: 'Geometry-DB-Icon',
      },
      {
        routerLink: '/toollimits/',
        image: '/assets/images/icons/svgs/tool_limits_icon.svg',
        alt: 'Geometry db',
        title: 'Tool Limits',
        otherName: '(DS-1)',
        dataCy: 'Tool-Limits-Icon',
      },
    ];
  
}
