<section class="landing-page-warraper">
  <div class="header">
    <mat-toolbar>
      <button mat-icon-button
        [attr.data-cy]="'info-button'">
        <mat-icon [matMenuTriggerFor]="menu1">info</mat-icon>
      </button>
      <mat-menu #menu1="matMenu" yPosition="above">
        <button mat-menu-item (click)="showSmdcReleaseNotes()"
         [attr.data-cy]="'info-option-1'">SMDC Landing Page - Release Notes</button>
      </mat-menu>
      <button mat-icon-button
        [attr.data-cy]="'setting-button'"
        [disabled]="true"
        [class.disabled]="true">
        <mat-icon>settings</mat-icon
        >
      </button>
      <button mat-icon-button
        [attr.data-cy]="'profile-button'"
        [disabled]="true"
        [class.disabled]="true">
        <mat-icon>account_circle</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div class="landing-block landing-page-header" id="app-header">
    <div class="landing-page-header--title" id="app-title" [attr.data-cy]="appName">{{ appName }}</div>
    <div class="landing-page-header--desc" id="app-desc" [attr.data-cy]="appDescription">
      {{ appDescription }}
    </div>
  </div>
  <div class="landing-block landing-page-app" id="app-list">
    <div *ngFor="let item of items" class="landing-page-app--item" [title]="item.title"
      (click)="routerLink(item.routerLink)">
      <img [src]="item.image" [alt]="item.alt" [attr.data-cy]="item.dataCy" />
      <span> {{ item.title }}<span *ngIf="item.hasTrademark" class="landing-page-app--item--trademark">®</span><span
          class="landing-page-app--item--other-name" *ngIf="item.otherName">{{item.otherName}}</span></span>
    </div>
  </div>

  <div class="landing-block"></div>

  <div class="landing-block landing-page-footer" id="app-footer">
    <div class="landing-page-footer--logo" [attr.data-cy]="'footer--logo'">
      <img src="../../../../assets/images/background/Hal-logo2024.png" alt="Halliburton Logo 2023" />
    </div>
  </div>
  <div></div>
  <router-outlet></router-outlet>
</section>