const oktaConfigDev = {  
  issuer: 'https://myappstest.halliburton.com/oauth2/aus1d5zdpivC2CZF60h8',  
  // clientId: '0oa13v25sxknUI5p60h8',
  clientId: '0oa1v63n2qz9VN7MR0h8',
  // redirectUri: '/login/callback',
  redirectUri: 'https://dev.smdc.ienergy.halliburton.com/login/callback',  
  scopes: ['openid', 'profile'],
};

export const environment = {
  production: false,
  okta: {
    issuer: oktaConfigDev.issuer,
    clientId: oktaConfigDev.clientId,
    redirectUri: oktaConfigDev.redirectUri,
    scopes: oktaConfigDev.scopes,
  },
};
