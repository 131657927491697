import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { HTTP_INTERCEPTORS as HttpInterceptors, HttpClientModule } from '@angular/common/http';
import { OKTA_CONFIG as OktaConfig, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { HomeComponent } from './modules/home/pages/home.component';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { environment } from 'src/environments/environment';
import { DummyComponent } from './shared/components/dummy/dummy.component';

const oktaConfig = environment.okta;
const oktaAuth = new OktaAuth(oktaConfig);

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    title: 'SMDC',
    component: HomeComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'imotors',
    title: 'SMDC - iMotors',
    component: HomeComponent,
  },
  {
    path: 'login/callback',
    title: 'SMDC',
    component: OktaCallbackComponent,
  },
  {
    path: 'icruise',
    title: 'SMDC - iCruise',
    component: DummyComponent,
  },
  {
    path: 'geometrydb',
    title: 'SMDC - Geometry db',
    component: DummyComponent,
  },
  {
    path: 'toollimits',
    title: 'SMDC - Tool Limits (DS-1)',
    component: DummyComponent,
  },
  {
    path: 'dummy-page',
    title: 'SMDC',
    component: DummyComponent,
  },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [HttpClientModule, OktaAuthModule, RouterModule.forRoot(routes)],
  providers: [
    { provide: OktaConfig, useValue: { oktaAuth } },
    { provide: HttpInterceptors, useClass: AuthInterceptor, multi: true },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}